import Location from '@/domain/Location';
import Dock from '@/domain/Dock';
import LocationAddress from '@/domain/LocationAddress';
import LocationType from '@/domain/LocationType';
import { getTranslation } from '@/services/TranslationService';
import { EMAIL_REGEX, nullableValueRuleFunction } from '@/validation/utils';
import { ValidationRules } from '@/validation/types';
import FloorLocation from '@/domain/FloorLocation';

export const newLocationFormRules: Array<ValidationRules<Location>> = [
    {
        key: 'name',
        name: 'core.domain.name',
        required: true,
        rules: [(value: string) => value.length <= 100 || getTranslation('core.validation.nameMustNotExceed100Characters')],
    },
    {
        key: 'shortName',
        name: 'core.domain.shortName',
        required: true,
        rules: [(value: string) => value.length <= 25 || getTranslation('core.validation.shortNameMustNotExceed25Characters')],
    },
    {
        key: 'type',
        name: 'core.domain.locationType',
        required: true,
        rules: [(value: LocationType) => (!!value.id && value.id > 0) || getTranslation('core.validation.locationTypeIsRequired')],
    },
    {
        key: 'nonWorkingTimeSetId',
        name: 'core.domain.calendar',
        required: true,
    },
    {
        key: 'taxId',
        rules: [(value: string) => value.length <= 50 || getTranslation('core.validation.taxIdMustNotExceed50Characters')],
    },
    {
        key: 'openTime',
        rules: [(value: Date, form: Location) => form.isOpen24Hours || value.getTime() < (form.closeTime?.getTime() || 0) || getTranslation('core.validation.openTimeCannotBeLaterThanCloseTime')],
    },
    {
        key: 'locationNumber',
        rules: [(value: string) => value.length <= 25 || getTranslation('core.validation.locationNumberMustNotExceed25Characters')],
    },
    {
        key: 'targetDaysOnHand',
        rules: [
            (value?: number) => nullableValueRuleFunction(
                value,
                (nonNullableValue) => nonNullableValue > 0 || getTranslation('core.validation.targetDaysOnHandMustBeGreaterThan0'),
            ),
        ],
    },
    {
        key: 'equipmentInspectionInterval',
        rules: [
            (value?: number) => nullableValueRuleFunction(
                value,
                (nonNullableValue) => (nonNullableValue > 0 && nonNullableValue <= 24) || getTranslation('core.validation.equipmentInspectionIntervalMustBeBetween1And24'),
            ),
        ],
    },
    {
        key: 'maxLoadPerTimeslot',
        rules: [
            (value?: number) => nullableValueRuleFunction(
                value,
                (nonNullableValue) => nonNullableValue > 0 || getTranslation('core.validation.maxLoadPerTimeslotMustBeGreaterThanZero'),
            ),
        ],
    },
];

export const updateLocationFormRules: Array<ValidationRules<Location>> = [
    ...newLocationFormRules,
    {
        key: 'firstPreferredServiceCenter',
        name: 'core.domain.preferredServiceCenter',
        required: false,
        rules: [(value: Location, form: Location) => value.id !== form.id || getTranslation('core.validation.preferredServiceCenterCannotBeTheSameAsTheLocation')],
    },
    {
        key: 'secondPreferredServiceCenter',
        required: false,
        rules: [
            (value: Location, form: Location) => value.id !== form.id || getTranslation('core.validation.secondPreferredServiceCenterCannotBeTheSameAsTheLocation'),
            // if id === 0, we should return true and pass this rule, no need to see if it already exists
            (value: Location, form: Location) => value.id === 0 || value.id !== form.firstPreferredServiceCenter?.id || getTranslation('core.validation.isAlreadyAPreferredServiceCenter', form.secondPreferredServiceCenter!.name),
        ],
    },
    {
        key: 'thirdPreferredServiceCenter',
        required: false,
        rules: [
            (value: Location, form: Location) => value.id !== form.id || getTranslation('core.validation.thirdPreferredServiceCenterCannotBeTheSameAsTheLocation'),
            // if id === 0, we should return true and pass this rule, no need to see if it already exists
            (value: Location, form: Location) => value.id === 0 || value.id !== form.firstPreferredServiceCenter?.id || getTranslation('core.validation.isAlreadyAPreferredServiceCenter', form.thirdPreferredServiceCenter!.name),
            (value: Location, form: Location) => value.id === 0 || value.id !== form.secondPreferredServiceCenter?.id || getTranslation('core.validation.isAlreadyAPreferredServiceCenter', form.thirdPreferredServiceCenter!.name),
        ],
    },
];

export const updateLocationAddressFormRules: Array<ValidationRules<LocationAddress>> = [
    {
        key: 'type',
        name: 'core.domain.type',
        required: true,
    },
    {
        key: 'displayName',
        name: 'core.domain.displayName',
        required: true,
        rules: [(value: string) => value.length <= 100 || getTranslation('core.validation.displayNameMustNotExceed100Characters')],
    },
    {
        key: 'contactName',
        name: 'core.common.contactName',
        required: true,
        rules: [(value: string) => value.length <= 100 || getTranslation('core.validation.contactNameMustNotExceed100Characters')],
    },
    {
        key: 'phoneNumber',
        name: 'core.domain.phoneNumber',
        required: true,
        rules: [(value: string) => value.length <= 25 || getTranslation('core.validation.phoneNumberMustNotExceed25Characters')],
    },
    {
        key: 'emailAddress',
        name: 'core.domain.email',
        required: true,
        rules: [
            (value: string) => value.length <= 200 || getTranslation('core.validation.emailMustNotExceed200Characters'),
            (value: string) => value.split(',').every((x) => EMAIL_REGEX.test(x)) || getTranslation('core.validation.pleaseEnterValidEmailAddressesSeparatedByCommas'),
        ],
    },
    {
        key: 'streetAddress',
        name: 'core.domain.streetAddress',
        required: true,
    },
    {
        key: 'city',
        name: 'core.domain.city',
        required: true,
    },
    {
        key: 'state',
        name: 'core.domain.state',
        required: true,
    },
    {
        key: 'country',
        name: 'core.domain.country',
        required: true,
    },
    {
        key: 'zip',
        name: 'core.domain.zip',
        required: true,
    },
];

export const dockFormRules: Array<ValidationRules<Dock>> = [
    {
        key: 'name',
        name: 'core.domain.name',
        required: true,
        rules: [(value: string) => value.length <= 50 || getTranslation('core.validation.dockNameMustNotExceed50Characters')],
    },
    {
        key: 'typeId',
        name: 'core.domain.typeId',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.dockTypeIsRequired')],
    },
];

export const floorLocationFormRules: Array<ValidationRules<FloorLocation>> = [
    {
        key: 'name',
        name: 'core.domain.name',
        required: true,
        rules: [(value: string) => value.length < 50 || getTranslation('core.validation.floorLocationNameMustNotExceed50Characters')],
    },
    {
        key: 'dimensions',
        name: 'core.domain.dimensions',
        required: false,
        rules: [(value: string) => value.length === 0 || /\d+x\d+$/i.test(value) || getTranslation('core.validation.floorLocationDimensionsMustBeNumberXNumber')],
    },
];
