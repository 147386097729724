import { getTranslation } from '@/services/TranslationService';
import { ValidationRules } from '@/validation/types';
import ItemTransformation from '@/domain/ItemTransformation';

const itemTransformationFormRules: Array<ValidationRules<ItemTransformation>> = [
    {
        key: 'locationId',
        name: 'core.domain.location',
        required: true,
    },
    {
        key: 'fromItemId',
        name: 'core.domain.fromItem',
        required: true,
        rules: [(value: number, itemTransformation: ItemTransformation) => value !== itemTransformation.toItemId || getTranslation('core.validation.fromItemAndToItemCannotBeTheSame')],
    },
    {
        key: 'toItemId',
        name: 'core.domain.toItem',
        required: true,
    },
    {
        key: 'quantity',
        name: 'core.domain.quantity',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.quantityCannotBeZeroOrNegative')],
    },
];

export default itemTransformationFormRules;
