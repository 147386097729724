import Item from '@/domain/Item';
import Location from '@/domain/Location';
import PlannedPart from '@/domain/PlannedPart';
import ProductionPart from '@/domain/ProductionPart';
import { ValidationRules } from '@/validation/types';
import { getTranslation } from '@/services/TranslationService';

const plannedPartAddRules: Array<ValidationRules<PlannedPart>> = [
    {
        key: 'quantityPerItem',
        name: 'core.domain.quantityPerContainer',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.quantityPerItemRequired')],
    },
    {
        key: 'effectiveFrom',
        rules: [
            (value: Date, part: PlannedPart) => {
                if (!part.effectiveTo) {
                    return true;
                }
                if (value && value > new Date(part.effectiveTo)) {
                    return getTranslation('core.validation.effectiveFromMustBeLessThanEffectiveTo');
                }

                return true;
            },
        ],
    },
    {
        key: 'effectiveTo',
        rules: [
            (value: Date, part: PlannedPart) => {
                if (!value) {
                    return true;
                }
                if (part.effectiveFrom && value < part.effectiveFrom) {
                    return getTranslation('core.validation.effectiveToMustBeGreaterThanEffectiveFrom');
                }

                return true;
            },
        ],
    },
    {
        key: 'part',
        name: 'core.domain.partNumber',
        required: true,
        rules: [(value: ProductionPart) => (value && value.id > 0) || getTranslation('core.validation.partNumberRequired')],
    },
    {
        key: 'fromLocation',
        name: 'core.domain.fromLocation',
        required: true,
        rules: [(value: Location) => (value && value.id > 0) || getTranslation('core.validation.fromLocationRequired')],
    },
    {
        key: 'toLocation',
        name: 'core.domain.toLocation',
        required: true,
        rules: [(value: Location) => (value && value.id > 0) || getTranslation('core.validation.toLocationRequired')],
    },
    {
        key: 'item',
        name: 'core.domain.item',
        required: true,
        rules: [(value: Item) => (value && value.id > 0) || getTranslation('core.validation.containerRequired')],
    },
    {
        key: 'palletItem',
        name: 'core.domain.palletContainerNumber',
        required: 'isPalletized',
        rules: [(value?: Item) => (value && value.id > 0) || getTranslation('core.validation.palletContainerRequired')],
    },
    {
        key: 'itemsPerPallet',
        name: 'core.domain.containersPerPallet',
        required: 'isPalletized',
        rules: [(value?: number) => (value && value > 0) || getTranslation('core.validation.containersPerPalletRequired')],
    },
    {
        key: 'topCapItem',
        name: 'core.domain.topCapItemNumber',
        required: 'includeTopCap',
        rules: [(value?: Item) => (value && value.id > 0) || getTranslation('core.validation.topCapContainerRequired')],
    },
];

export default plannedPartAddRules;
