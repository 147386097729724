import RepairPart from '@/domain/Repair/RepairPart';
import RepairPartInventory from '@/domain/Repair/RepairPartInventory';
import { getTranslation } from '@/services/TranslationService';
import { ValidationRules } from '@/validation/types';

export const repairPartFormRules: Array<ValidationRules<RepairPart>> = [
    {
        key: 'partNumber',
        name: 'core.domain.partNumber',
        required: true,
        rules: [(value: string) => value.length <= 100 || getTranslation('core.validation.partNumberMustNotExceed100Characters')],
    },
    {
        key: 'description',
        name: 'core.domain.description',
        required: true,
        rules: [(value: string) => value.length <= 250 || getTranslation('core.validation.descriptionMustNotExceed250Characters')],
    },
    {
        key: 'reorderPoint',
        name: 'core.domain.reorderPoint',
        required: true,
    },
    {
        key: 'billingPrice',
        name: 'core.domain.billingPrice',
        required: true,
    },
];

export const repairPartInventoryFormRules: Array<ValidationRules<RepairPartInventory>> = [
    {
        key: 'repairPartId',
        name: 'core.domain.repairPart',
        required: true,
    },
    {
        key: 'locationId',
        name: 'core.domain.location',
        required: true,
    },
    {
        key: 'quantity',
        name: 'core.domain.quantity',
        required: true,
    },
];
