import TrackedItem from '@/domain/TrackedItem';
import { ValidationRules } from '@/validation/types';
import { getTranslation } from '@/services/TranslationService';

const barcodeLengthRule: ValidationRules<TrackedItem> = {
    key: 'barcode',
    required: true,
    name: 'core.domain.fromCategory',
    rules: [(value: string) => value.length > 3 || getTranslation('core.validation.barcodeMinimumFourCharacters')],
};

export const updateTrackedItemRules: Array<ValidationRules<TrackedItem>> = [barcodeLengthRule];

export const createTrackedItemRules: Array<ValidationRules<TrackedItem>> = [
    barcodeLengthRule,
    {
        key: 'itemId',
        name: 'core.domain.item',
        required: true,
    },
];
