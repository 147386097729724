import ApplicationUser from '@/domain/ApplicationUser';
import Role from '@/domain/Role';
import Location from '@/domain/Location';
import Permission from '@/domain/Permission';
import { getTranslation } from '@/services/TranslationService';
import {
    CONTAINS_NO_WHITE_SPACE_REGEX, CONTAINS_SPECIAL_CHARS_REGEX, EMAIL_REGEX, ALPHANUMERIC_REGEX,
} from '@/validation/utils';
import { ValidationRules } from '@/validation/types';

export const applicationUserFormRules: Array<ValidationRules<ApplicationUser>> = [
    {
        key: 'username',
        name: 'core.domain.username',
        required: true,
        rules: [
            (value: string) => value.length <= 50 || getTranslation('core.validation.usernameMustNotExceed50Characters'),
            (value: string) => !CONTAINS_SPECIAL_CHARS_REGEX.test(value) || getTranslation('core.validation.usernameCannotContainSpecialCharacters'),
            (value: string) => CONTAINS_NO_WHITE_SPACE_REGEX.test(value) || getTranslation('core.validation.usernameCannotContainWhitespace'),
        ],
    },
    {
        key: 'email',
        name: 'core.domain.emailAddress',
        rules: [
            (value: string) => EMAIL_REGEX.test(value) || getTranslation('core.validation.emailMustBeAValidEmailAddress'),
            (value: string) => CONTAINS_NO_WHITE_SPACE_REGEX.test(value) || getTranslation('core.validation.emailAddressCannotContainWhitespace'),
        ],
    },
    {
        key: 'firstName',
        name: 'core.domain.firstName',
        required: true,
    },
    {
        key: 'lastName',
        name: 'core.domain.lastName',
        required: true,
    },
    {
        key: 'userRoles',
        name: 'core.domain.userRoles',
        required: true,
        rules: [(value: Array<Role>) => value.length > 0 || getTranslation('core.validation.userMustHaveAtLeastOneRoleAssigned')],
    },
    {
        key: 'locations',
        name: 'core.domain.userLocations',
        required: true,
        rules: [(value: Array<Location>) => value.length > 0 || getTranslation('core.validation.userMustHaveAtLeastOneLocationAssigned')],
    },
    {
        key: 'loginType',
        name: 'core.domain.loginType',
        required: true,
    },
    {
        key: 'phoneNumber',
        rules: [(value: string) => value.length <= 25 || getTranslation('core.validation.phoneNumberMustNotExceed25Characters')],
    },
    {
        key: 'samAccountName',
        name: 'core.domain.samAccountName',
        rules: [
            (value: string) => value.length <= 100 || getTranslation('core.validation.samAccountNameMustNotExceed100Characters'),
        ],
    },
];

export const roleRules: Array<ValidationRules<Role>> = [
    {
        key: 'name',
        required: true,
        rules: [(value: string) => (value.length > 0 && value.length <= 35 && ALPHANUMERIC_REGEX.test(value)) || getTranslation('core.validation.roleNameMustNotExceed35Characters')],
    },
    {
        key: 'rolePermissions',
        name: 'core.domain.rolePermissions',
        required: true,
        rules: [(value: Array<Permission>) => value.length > 0 || getTranslation('core.validation.roleMustHaveAtLeast1PermissionAssigned')],
    },
];
