import PlannedReservation from '@/domain/PlannedReservation';
import Location from '@/domain/Location';
import Carrier from '@/domain/Carrier';
import { ValidationRules } from '@/validation/types';
import useStringFormatter from '@/composable/useStringFormatter';
import { getTranslation } from '@/services/TranslationService';

const { capitalizeFirstLetter } = useStringFormatter();

const newPlannedReservationRule: Array<ValidationRules<PlannedReservation>> = [
    {
        key: 'fromLocation',
        name: 'core.domain.fromLocation',
        required: true,
        rules: [(value: Location) => value?.id > 0 || capitalizeFirstLetter(getTranslation('core.validation.fromLocationRequired'))],
    },
    {
        key: 'carrier',
        name: 'core.domain.carrierReferenceNumber',
        required: true,
        rules: [(value: Carrier) => value?.id > 0 || capitalizeFirstLetter(getTranslation('core.validation.carrierRequired'))],
    },
    {
        key: 'carrierReferenceNumber',
        name: 'core.domain.carrierReferenceNumber',
        required: true,
        rules: [(value: string) => value.length <= 30 || getTranslation('core.validation.carrierReferenceNumberIsTooLong')],
    },
    {
        key: 'trailerNumber',
        name: 'core.domain.trailer',
        required: false,
        rules: [(value: string) => value.length <= 50 || getTranslation('core.validation.trailerNumberIsTooLong')],
    },
    {
        key: 'partnerReferenceNumber',
        name: 'core.domain.partnerReferenceNumber',
        required: false,
        rules: [(value: string) => value.length <= 30 || getTranslation('core.validation.partnerReferenceNumberIsTooLong')],
    },
];

export default newPlannedReservationRule;
