import { ValidationRules } from '@/validation/types';
import SortStation from '@/domain/SortStation';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

const sortStationFormRules: Array<ValidationRules<SortStation>> = [
    {
        key: 'name',
        name: getTitleCaseTranslation('core.domain.name'),
        required: true,
        rules: [(value: string) => value.length <= 25 || getTranslation('core.validation.nameMustNotExceed25Characters')],
    },
];

export default sortStationFormRules;
