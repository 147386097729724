import { WarehouseMovementData } from '@/modules/floortrak/types/WarehouseMovementData';
import { ValidationRules } from '@/validation/types';
import { getTranslation } from '@/services/TranslationService';

const warehouseMovementRules: Array<ValidationRules<WarehouseMovementData>> = [
    {
        key: 'fromCategoryId',
        name: 'core.domain.fromCategory',
        required: true,
    },
    {
        key: 'toCategoryId',
        name: 'core.domain.toCategory',
        required: true,
    },
    {
        key: 'quantity',
        name: 'core.domain.quantity',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.mustEnterMovementQuantity')],
    },
    {
        key: 'locationId',
        name: 'core.domain.location',
        required: true,
    },
    {
        key: 'itemId',
        name: 'core.domain.item',
        required: true,
    },
];

export default warehouseMovementRules;
