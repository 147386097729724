import { reactive, onMounted, onUnmounted } from 'vue';
import useStringFormatter from '@/composable/useStringFormatter';
import { useNotification } from '@/composable/useNotifications';
import {
    FormValidationKey, ValidationModel, ValidationResult, ValidationRules, Validator, ChildValidator,
} from '@/validation/types';
import { getValidationRules } from '@/validation/utils';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

// use when validation is optional and you need to pass something in so it doesnt break
// alternative to this is polluting everything with ternaries
export const emptyValidationResult: ValidationResult<unknown> = {
    isValid: true,
    model: {},
    errorList: [],
};

export function useValidator<T>(key: FormValidationKey): Validator<T> {
    const { capitalizeFirstLetter } = useStringFormatter();

    const validationResult = reactive({
        isValid: true,
        errorList: [],
        model: <ValidationModel<T>>{},
    }) as ValidationResult<T>;

    let notifications: string[] = [];
    let shouldNotify = true;

    /**
     * initialize the validator results
     */
    function clearResults() {
        validationResult.isValid = true;
        validationResult.errorList = [];
        validationResult.model = <ValidationModel<T>>{};
    }

    function skipNotifications() {
        shouldNotify = false;
    }

    /**
     * Validate rules for a complex object property
     *
     * @param validator the validator to apply to each element
     * @param parentKey the name of the child object on the parent
     * @param childObject the object to validate
     */
    function validateChild<C>(validator: ChildValidator<C>, parentKey: keyof T, childObject: C) {
        // validate single child type
        validator.validateForm(childObject);
        const childResult = validator.validationResult;
        validationResult.model[parentKey] = childResult.model;
        // propagate failure
        if (childResult.isValid === false) {
            validationResult.isValid = false;
            validationResult.errorList.push(...childResult.errorList);
        }
        validator.clearResults();
    }

    /**
     * Validate every element in a child array property
     *
     * @param validator the validator to apply to each element
     * @param parentKey the name of the collection on the parent
     * @param childArray the collection to validate
     * @param childKey the identifier for child elements (optional)
     */
    function validateChildren<C>(validator: ChildValidator<C>, parentKey: keyof T, childArray: C[], childKey?: keyof C) {
        // validate array of child type
        const arrayResult: Record<string, ValidationModel<C>> = {};
        childArray.forEach((childValue, index) => {
            // set result to parent by index or key if added
            validator.validateForm(childValue);
            const childResult = validator.validationResult;
            const resultKey = (childKey ? childValue[childKey] : index) as string & C[keyof C];
            arrayResult[resultKey] = childResult.model;

            // propagate failure
            if (childResult.isValid === false) {
                validationResult.isValid = false;
                validationResult.errorList.push(...childResult.errorList);
            }
            validator.clearResults();
        });
        validationResult.model[parentKey] = arrayResult;
    }

    /**
     * Validate rules for this validator if they exist
     *
     * @param vr the validation rules to check
     * @param formKeyValue the value to validate
     * @param form the form object
     */
    function tryValidateRules(vr: ValidationRules<T>, formKeyValue: T[keyof T], form: T) {
        if (vr.rules) {
            const error = vr.rules.map((rule) => rule(formKeyValue, form)).find((result) => typeof result === 'string') as string;

            if (error) {
                validationResult.errorList.push(error);
                validationResult.isValid = false;
                if (vr.child && Array.isArray(formKeyValue)) {
                    // show collection level error
                    notifications.push(error);
                } else {
                    validationResult.model[vr.key] = error;
                }
            }
        }
    }

    /**
     * Validate child rules for this validator if they exist
     *
     * @param vr the validation rules to check
     * @param formKeyValue the value to validate
     */
    function tryValidateChildRules(vr: ValidationRules<T>, formKeyValue: T[keyof T]) {
        if (vr.child) {
            // a child validator exists, recursively validate
            const childValidator = vr.child();
            const { childKey } = childValidator;

            if (Array.isArray(formKeyValue)) {
                validateChildren(childValidator, vr.key, formKeyValue, childKey);
            } else {
                validateChild(childValidator, vr.key, formKeyValue);
            }
        }
    }

    function tryTranslatePropertyName(name: string) {
        const result = getTitleCaseTranslation(name);
        return result?.length ? result : name;
    }

    /**
     * Validate that the field has a value to validate
     * and is not required if it does not have a value
     *
     * @param vr the validation rules to check
     * @param formKeyValue the value to validate
     * @param form the form object
     */
    function shouldValidateRules(vr: ValidationRules<T>, formKeyValue: T[keyof T], form: T): boolean {
        // value is required, if the value exists or is nonempty, validate rules
        const isEmptyString = typeof formKeyValue === 'string' && formKeyValue === '';
        const hasValue = formKeyValue !== null && formKeyValue !== undefined && !isEmptyString;
        const isEmptyArray = Array.isArray(formKeyValue) && formKeyValue.length === 0;
        if (hasValue && !isEmptyArray) {
            return true;
        }

        // value was not provided, check if it is required
        let isRequired = false;
        if (typeof vr.required === 'string') {
            isRequired = !!form[vr.required];
        } else {
            isRequired = !!vr.required;
        }

        // validate required rule
        if (isRequired) {
            const propertyName = tryTranslatePropertyName(`${vr.name ? vr.name : useStringFormatter().camelCaseToLabel(vr.key as string)}`);
            let error;
            if (vr.child && Array.isArray(formKeyValue)) {
                // treat collection level required as non empty
                error = `${propertyName} ${getTranslation('core.validation.cannotBeEmpty')}`;
                notifications.push(error);
            } else {
                // property is required
                error = `${propertyName} ${getTranslation('core.validation.isRequired')}`;
                validationResult.model[vr.key] = error;
            }
            validationResult.isValid = false;
            validationResult.errorList.push(error);
        }

        // do not validate missing fields
        return false;
    }

    /**
     * Validate the form object
     *
     * @param form the form to validate
     */
    function validateForm(form: T) {
        // allows us to bypass client side validation in development or stage for testing server side validation
        if (process.env.VUE_APP_MODE === 'development' || process.env.VUE_APP_MODE === 'stage') {
            if (sessionStorage.getItem('bypass-client-validation')) {
                clearResults();
                validationResult.isValid = true;
                return;
            }
        }

        clearResults();

        // validate this form against the validation rules
        const validationRules = getValidationRules(key) as ValidationRules<T>[];
        validationRules.forEach((vr) => {
            const formKeyValue = form[vr.key];
            if (shouldValidateRules(vr, formKeyValue, form)) {
                // value exists, check rules
                tryValidateRules(vr, formKeyValue, form);
                tryValidateChildRules(vr, formKeyValue);
            }
        });

        if (!validationResult.isValid && shouldNotify) {
            // TODO: how can I decouple these composables? or do we even want this to deploy?
            const notification = notifications.length ? notifications[0] : `${key}: ${capitalizeFirstLetter(getTranslation('core.validation.oneOrMoreErrors'))}`;

            useNotification().showValidationError([notification]);
            notifications = [];
        }
    }

    // auto map errors when property names match. show any unmatched errors in a notification
    function mapErrors(details: any, msgId?: string) {
        let keys = Object.keys(details);
        getValidationRules(key).forEach((x) => {
            if (details[x.key as keyof typeof details]) {
                validationResult.model[x.key as keyof typeof validationResult.model] = details[x.key as keyof typeof details];
                keys = keys.filter((y) => y !== x.key);
            }
        });

        // if there are any keys left it means there are errors we couldn't map that should be displayed as a notification
        const notification = keys.length ? keys.map((x) => details[x as keyof typeof details]) : [`${key}: ${capitalizeFirstLetter(getTranslation('core.validation.oneOrMoreErrors'))}`];
        useNotification().showValidationError(notification, msgId);
    }

    function handleErrorEvent(event: Event) {
        const details = (event as CustomEvent).detail;
        if (!details) {
            return;
        }

        // use the event timestamp as id to prevent pages with multiple validators from showing duplicate notifications
        mapErrors(details, event.timeStamp.toString());
    }

    onMounted(() => window.addEventListener('validationError', handleErrorEvent));
    onUnmounted(() => window.removeEventListener('validationError', handleErrorEvent));

    return {
        validateForm,
        validationResult,
        clearResults,
        skipNotifications,
        mapErrors,
    };
}

export function useChildValidator<T>(key: FormValidationKey, childKey?: string & keyof T): ChildValidator<T> {
    const validator = useValidator<T>(key) as ChildValidator<T>;
    validator.skipNotifications();
    if (childKey) {
        validator.childKey = childKey;
    }
    return validator;
}

export default useValidator;
