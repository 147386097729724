import { ValidationRules } from '@/validation/types';
import CycleCountConfiguration from '@/domain/cyclecount/CycleCountConfiguration';
import { getTranslation } from '@/services/TranslationService';

const cycleCountConfigurationFormRules: Array<ValidationRules<CycleCountConfiguration>> = [
    {
        key: 'allLocations',
        name: 'core.common.all',
        rules: [(value: boolean, config: CycleCountConfiguration) => ((!value && ((config.locations && config.locations.length > 0) || (config.locationTypes && config.locationTypes.length > 0)))
            || (value && config.locations?.length === 0 && config.locationTypes?.length === 0)
            || getTranslation('core.validation.cycleCountConfigurationLocationDataInvalid'))],
    },
    {
        key: 'allItems',
        name: 'core.domain.allItems',
        rules: [(value: boolean, config: CycleCountConfiguration) => ((!value && ((config.items && config.items.length > 0) || (config.itemTypes && config.itemTypes.length > 0)))
            || (value && config.items?.length === 0 && config.itemTypes?.length === 0)
            || getTranslation('core.validation.cycleCountConfigurationItemDataInvalid'))],
    },
    {
        key: 'allInventoryCategories',
        name: 'core.domain.allInventoryCategories',
        rules: [(value: boolean, config: CycleCountConfiguration) => ((!value && config.canCountInventoryCategories && config.canCountInventoryCategories.length > 0)
            || (value && config.canCountInventoryCategories?.length === 0)
            || getTranslation('core.validation.cycleCountConfigurationInventoryCategoryDataInvalid'))],
    },
    {
        key: 'description',
        name: 'core.domain.description',
        required: 'cronSchedule',
        rules: [(value: string) => (value && value.length <= 50) || getTranslation('core.validation.descriptionShouldNotExceed50Characters')],
    },
    {
        key: 'cronSchedule',
        name: 'core.domain.recurrence',
        // By default, the recurrence string is valid, but the only instance when it's not is when selecting "weekly" with no days of the week
        rules: [(value: string) => (value && value.split(' ').length === 6) || getTranslation('core.validation.selectDaysOfWeek')],
    },
];

export default cycleCountConfigurationFormRules;
