import Equipment from '@/domain/Equipment';
import { ValidationRules } from '@/validation/types';
import { getTranslation } from '@/services/TranslationService';

const equipmentFormRules: Array<ValidationRules<Equipment>> = [
    {
        key: 'name',
        name: 'core.domain.name',
        required: true,
        rules: [(value: string) => (value && value.length <= 100) || getTranslation('core.validation.nameMustNotExceed100Characters')],
    },
    {
        key: 'equipmentNumber',
        name: 'core.domain.number',
        required: true,
        rules: [(value: string) => (value && value.length <= 100) || getTranslation('core.validation.numberMustNotExceed100Characters')],
    },
    {
        key: 'typeId',
        name: 'core.domain.type',
        required: true,
        rules: [(value: number) => value > 0 || getTranslation('core.validation.equipmentTypeRequired')],
    },
    {
        key: 'make',
        name: 'core.domain.make',
        rules: [(value: string) => (value && value.length <= 100) || getTranslation('core.validation.makeMustNotExceed100Characters')],
    },
    {
        key: 'model',
        name: 'core.domain.model',
        rules: [(value: string) => (value && value.length <= 100) || getTranslation('core.validation.modelMustNotExceed100Characters')],
    },
    {
        key: 'serialNumber',
        name: 'core.domain.serialNumber',
        rules: [(value: string) => (value && value.length <= 100) || getTranslation('core.validation.serialNumberMustNotExceed100Characters')],
    },
    {
        key: 'locationId',
        name: 'core.domain.location',
        required: true,
        rules: [(value: number) => (value > 0) || getTranslation('core.validation.locationIsRequired')],
    },
];

export default equipmentFormRules;
