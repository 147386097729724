import { getTitleCaseTranslation } from '@/services/TranslationService';

export enum TransactionStatus {
    ORDERED = 0,
    PLANNED = 2,
    ALLOCATED = 5,
    TENDERED = 10,
    SCHEDULED = 20,
    AWAITING_PICK = 30,
    ACTIVE = 40,
    PICKED = 50,
    HELD = 60,
    IN_TRANSIT = 70,
    DELIVERY_CONFIRMATION_PENDING = 80,
    DELIVERY_CONFIRMED = 90,
    DISPUTED = 100,
    CONFIRMED = 110,
    DELETED = 120,
}

export function getAllStatuses(): TransactionStatus[] {
    const statusValues: TransactionStatus[] = [];
    Object.values(TransactionStatus)
        .filter((val) => typeof val === 'number')
        .forEach((status) => {
            if (typeof status !== 'string') {
                statusValues.push(status);
            }
        });
    return statusValues;
}

export class Formatter {
    public static GetFriendlyValue(status: TransactionStatus) {
        switch (status) {
        case TransactionStatus.ORDERED:
            return getTitleCaseTranslation('core.transactionStatus.ORDERED');
        case TransactionStatus.PLANNED:
            return getTitleCaseTranslation('core.transactionStatus.PLANNED');
        case TransactionStatus.ALLOCATED:
            return getTitleCaseTranslation('core.transactionStatus.ALLOCATED');
        case TransactionStatus.TENDERED:
            return getTitleCaseTranslation('core.transactionStatus.TENDERED');
        case TransactionStatus.SCHEDULED:
            return getTitleCaseTranslation('core.transactionStatus.SCHEDULED');
        case TransactionStatus.AWAITING_PICK:
            return getTitleCaseTranslation('core.transactionStatus.AWAITING_PICK');
        case TransactionStatus.ACTIVE:
            return getTitleCaseTranslation('core.transactionStatus.ACTIVE');
        case TransactionStatus.PICKED:
            return getTitleCaseTranslation('core.transactionStatus.PICKED');
        case TransactionStatus.HELD:
            return getTitleCaseTranslation('core.transactionStatus.HELD');
        case TransactionStatus.IN_TRANSIT:
            return getTitleCaseTranslation('core.transactionStatus.IN_TRANSIT');
        case TransactionStatus.DELIVERY_CONFIRMATION_PENDING:
            return getTitleCaseTranslation('core.transactionStatus.DELIVERY_CONFIRMATION_PENDING');
        case TransactionStatus.DELIVERY_CONFIRMED:
            return getTitleCaseTranslation('core.transactionStatus.DELIVERY_CONFIRMED');
        case TransactionStatus.DISPUTED:
            return getTitleCaseTranslation('core.transactionStatus.DISPUTED');
        case TransactionStatus.CONFIRMED:
            return getTitleCaseTranslation('core.transactionStatus.CONFIRMED');
        case TransactionStatus.DELETED:
            return getTitleCaseTranslation('core.transactionStatus.DELETED');
        default:
            return '-';
        }
    }
}
