import ProductionPart from '@/domain/ProductionPart';
import { ValidationRules } from '@/validation/types';
import { getTranslation } from '@/services/TranslationService';

const productionPartAddRules: Array<ValidationRules<ProductionPart>> = [
    {
        key: 'number',
        name: 'core.domain.partNumber',
        required: true,
        rules: [(value: string) => value.length <= 50 || getTranslation('core.validation.maximumTextLength50')],
    },
    {
        key: 'description',
        required: false,
        rules: [(value: string) => value.length <= 50 || getTranslation('core.validation.maximumTextLength50')],
    },
];

export default productionPartAddRules;
