import Shipment from '@/domain/Shipment';
import TrailerType from '@/domain/TrailerType';
import Location from '@/domain/Location';
import Dock from '@/domain/Dock';
import { ValidationRules } from '@/validation/types';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import { useChildValidator } from '@/validation/useValidator';
import Carrier from '@/domain/Carrier';
import Transaction from '@/domain/Transaction';

const dockRequiredRule: ValidationRules<Shipment> = {
    key: 'dock',
    name: 'core.domain.dock',
    required: true,
    rules: [(value: Dock) => (!!value.id && value.id > 0) || getTitleCaseTranslation('core.validation.dockRequired')],
};

const carrierIdRequiredRule: ValidationRules<Shipment> = {
    key: 'carrierId',
    name: 'core.domain.carrier',
    required: true,
    rules: [(value: number) => (!!value && value > 0) || getTitleCaseTranslation('core.validation.carrierRequired')],
};

const trailerTypeRequiredRule: ValidationRules<Shipment> = {
    key: 'trailerType',
    name: 'core.domain.trailerType',
    required: true,
    rules: [(value: TrailerType) => (!!value.id && value.id > 0) || getTitleCaseTranslation('core.validation.trailerTypeRequired')],
};

const fromLocationRequiredRule: ValidationRules<Shipment> = {
    key: 'fromLocation',
    name: 'core.domain.fromLocation',
    required: true,
};

const ftShippingTransactionsChildValidatorRule: ValidationRules<Shipment> = {
    key: 'transactions',
    child: () => useChildValidator<Transaction>('ft-shipping-transactions'),
    required: true,
};

const commonShipmentFormRules: Array<ValidationRules<Shipment>> = [
    {
        key: 'carrierReferenceNumber',
        required: false,
    },
    {
        key: 'licensePlateNumber',
        required: false,
    },
    {
        key: 'lot',
        required: false,
    },
    {
        key: 'scheduledDockTimeSlot',
        required: false,
        rules: [
            (value: Date, shipment: Shipment) => shipment.fromLocation.openTime.getHours() <= value.getHours() || getTranslation('core.validation.mustBeGreaterThanOpeningHours'),
            (value: Date, shipment: Shipment) => shipment.fromLocation.closeTime.getHours() > value.getHours() || getTranslation('core.validation.mustBeOneHourLessThanClose'),
        ],
    },
];

const receivingShipmentFormRules: ValidationRules<Shipment>[] = [
    {
        key: 'carrier',
        name: 'core.domain.carrier',
        required: true,
        rules: [(value: Carrier) => (value.id && value.id > 0) || getTitleCaseTranslation('core.validation.carrierRequired')],
    },
    {
        key: 'fromLocation',
        name: 'core.domain.fromLocation',
        required: true,
        rules: [(value: Location) => (!!value.id && value.id > 0) || getTitleCaseTranslation('core.validation.fromLocationRequired')],
    },
    dockRequiredRule,
];

export const scheduledShipmentFormRules: Array<ValidationRules<Shipment>> = [
    {
        key: 'trailerType',
        name: 'core.domain.trailerType',
        required: 'carrierId',
    },
    ...commonShipmentFormRules,
];

export const shipmentFormRules: Array<ValidationRules<Shipment>> = [
    carrierIdRequiredRule,
    trailerTypeRequiredRule,
    {
        key: 'trailer',
        name: 'core.domain.trailer',
        required: true,
    },
    dockRequiredRule,
    ...commonShipmentFormRules,
];

export const pemSupplierShippingFormRules: Array<ValidationRules<Shipment>> = [
    carrierIdRequiredRule,
    trailerTypeRequiredRule,
    {
        key: 'trailer',
        name: 'core.domain.trailer',
        required: true,
    },
];

export const ftShippingShipmentFormRules: Array<ValidationRules<Shipment>> = [
    carrierIdRequiredRule,
    trailerTypeRequiredRule,
    {
        key: 'trailer',
        name: 'core.domain.trailer',
        required: true,
    },
    dockRequiredRule,
    fromLocationRequiredRule,
    ftShippingTransactionsChildValidatorRule,
    ...commonShipmentFormRules,
];

export const ftShippingShipmentFormSaveOnlyRules: Array<ValidationRules<Shipment>> = [
    carrierIdRequiredRule,
    trailerTypeRequiredRule,
    {
        key: 'trailer',
        name: 'core.domain.trailer',
        required: true,
    },
    fromLocationRequiredRule,
    ftShippingTransactionsChildValidatorRule,
    ...commonShipmentFormRules,
];

export const existingReceivingShipmentFormRules: Array<ValidationRules<Shipment>> = [...receivingShipmentFormRules];

export const newReceivingShipmentFormRules: ValidationRules<Shipment>[] = [
    ...receivingShipmentFormRules,
    {
        key: 'transactions',
        required: true,
        rules: [
            (value: Transaction[]) => {
                const blindReceiptTransaction = value[0];

                return (
                    (blindReceiptTransaction && blindReceiptTransaction.arrivedAt > blindReceiptTransaction.actualDepartureDate)
                    || getTranslation('core.validation.shipDateMustBeEarlierThanTheDockArrivalTime')
                );
            },
        ],
    },
];
